:root {
  --white: #fff;
  --black: #171717;
  --blue-400: #0293D6;
  --blue-500: #0052cc;
  --blue-600: #1063e0;
  --blue-700: #0046AE;
  --blue-800: #00245d;
  --yellow-500: #ffaa00;
  --yellow-600: #D99100;
  --orange-500: #ff7f00;
  --orange-600: #D96C00;
  --purple-900: #4f4257;
  --purple-700: #3E4095;
  --red-400: #DC3741;
  --red-500: #c4170c;
  --red-600: #A7140A;
  --green-500: #06aa48;
  --green-600: #05913D;
  --gray-900: #333;
  --gray-100: #eeeeee;
  --gray-200: #e6e6e6;
  --gray-250: #f1f3f4;
  --gray-300: #d1d1d1;
  --gray-400: #969696;
  --gray-500: #868686;
  --gray-600: #555;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 1rem;
}

body {
  background-color: #f9f9f9;
  color: #000;
}

body,
html {
  height: 100%;
}

audio{
  width: 14rem;
}

body,
button,
input {
  font-family: "Open Sans", sans-serif;
}

img {
  max-width: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

blockquote {
  border-left: 1rem solid #0293D6;
  padding: 1rem;
  background-color: #d1d1d1;
  margin: 1rem 0;
}

.ql-size-small{
  font-size: .8rem;
  margin: 0;
}