.cookies {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1rem;
  width: 15rem;
  background: var(--white);
  height: 15rem;
  z-index: 8;
  padding: 1rem;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border: var(--gray-300) solid 1px;
}

.cookies a{
  color: blue;
}

.cookies span{
  font-size: 1.8rem;
}

.cookies p{
  text-align: justify;
}

.button{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.cookies button{
  background-color: var(--blue-700);
  padding: .5rem;
  color: white;
  cursor: pointer;
  border: none;
}
